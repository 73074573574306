import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import {
  formatRfcTimeStringToDateAndTimeView,
  getDateTimeFromLocalDateTime,
} from '../../../../utils/dateAndTime/dateAndTime';
import { getBookingPreferencesForSelectedTime } from '../../../../utils/bookingPreferences/bookingPreferencesForSelectedTime';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { CalendarState, TFunction } from '../../controller';
import {
  BookingPreference,
  BookingPreferenceOption,
} from '../../../../utils/bookingPreferences/bookingPreferences';
import { isWaitingListFlow } from '../../../../utils/waitingList/waitingList';
import { MemoizedViewModalFactory } from '../viewModel';
import {
  CalendarErrors,
  Preference,
  SlotsStatus,
} from '../../../../types/types';
import { getFurthestValidUntilPlan } from '../../../../utils/pricingPlans/pricingPlans';
import { getPaymentDescription } from '../../../../utils/payment/payment';
import {
  createCtaViewModel,
  CtaViewModel,
  memoizedCtaViewModel,
} from '../ctaViewModel/ctaViewModel';

export type BookingDetailsPreferences = {
  bookingPreferences: BookingPreference[];
  titleText: string;
  clearText: string;
};

export type BookingDetailsViewModel = {
  serviceName: string;
  dateAndTime?: string;
  paymentDescription?: string;
  videoConferenceBadgeText?: string;
  preferences: BookingDetailsPreferences;
  ctaViewModel: CtaViewModel;
  alert?: string;
};

export const memoizedBookingDetailsViewModel: MemoizedViewModalFactory<BookingDetailsViewModel> =
  {
    dependencies: {
      state: [
        'availableServices',
        'selectableSlotsAtSelectedTime',
        'selectedTime',
        'selectedBookingPreferences',
        'calendarErrors',
        'rescheduleBookingDetails',
        'purchasedPricingPlans',
        'slotsStatus',
        'selectedVariantsOptions',
      ],
      settings: [
        'videoConferenceBadgeVisibility',
        'videoConferenceBadge',
        'bookingDetailsPricingPlanText',
        'waitlistIndication',
        'bookingDetailsClearText',
        'preferencesTitle',
        'buttonsFullWidth',
        'nextButton',
        'rescheduleButton',
        'pendingApprovalButton',
        'joinWaitlistButton',
        'locationLabel',
        'staffMemberLabel',
        'durationLabel',
      ],
      subDependencies: [memoizedCtaViewModel.dependencies],
    },
    createViewModel: createBookingDetailsViewModel,
  };

const getVideoConferencingBadge = (context: CalendarContext) => {
  const { getContent, settingsParams } = context;
  return getContent({
    settingsParam: settingsParams.videoConferenceBadge,
    translationKey: 'app.settings.defaults.video-conference-badge-text',
  });
};

export function createBookingDetailsViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): BookingDetailsViewModel {
  const { businessInfo, t, settings, settingsParams, getContent, experiments } =
    context;
  const dateRegionalSettingsLocale = businessInfo!.dateRegionalSettingsLocale!;
  const {
    availableServices,
    selectableSlotsAtSelectedTime,
    selectedTime,
    selectedBookingPreferences,
    calendarErrors,
    purchasedPricingPlans,
    slotsStatus,
  } = state;

  const isShowPricingPlanEndDateIndicationEnabled = experiments.enabled(
    'specs.bookings.ShowPricingPlanEndDateIndication',
  );
  const isCtaViewModelEnabled = experiments.enabled(
    'specs.bookings.calendarCtaViewModel',
  );

  const serviceName = availableServices[0].info.name;

  let alert;
  if (isShowPricingPlanEndDateIndicationEnabled) {
    if (calendarErrors.includes(CalendarErrors.NO_VALID_PRICING_PLAN_WARNING)) {
      const furthestValidUntilPlan = getFurthestValidUntilPlan(
        purchasedPricingPlans,
      );
      alert = furthestValidUntilPlan
        ? t('app.booking-details.pricing-plan-alert', {
            planName: furthestValidUntilPlan.planName,
            planDate: getDateTimeFromLocalDateTime(
              furthestValidUntilPlan.validUntil!,
            ),
          })
        : undefined;
    }
  }
  const shouldShowVideoConferenceBadge = settings.get(
    settingsParams.videoConferenceBadgeVisibility,
  );
  const isServiceOfferedOnline = availableServices[0].videoConferenceProviderId;
  const videoConferenceBadgeText =
    shouldShowVideoConferenceBadge && isServiceOfferedOnline
      ? getVideoConferencingBadge(context)
      : '';

  const paymentDescription = getPaymentDescription({
    state,
    context,
  });

  const dateAndTime = selectedTime
    ? formatRfcTimeStringToDateAndTimeView(
        selectedTime,
        dateRegionalSettingsLocale,
      )
    : '';
  const bookingPreferences = getBookingPreferencesForSelectedTime({
    selectableSlotsAtSelectedTime: selectableSlotsAtSelectedTime ?? [],
    selectedBookingPreferences,
    calendarErrors,
    context,
    state,
  });

  let ctaViewModel: CtaViewModel;
  if (isCtaViewModelEnabled) {
    ctaViewModel = createCtaViewModel({
      state,
      context,
      slots: selectableSlotsAtSelectedTime || [],
    });
  } else {
    const disableCTAWhenNoAvailabilityOnSelectedDate =
      slotsStatus === SlotsStatus.NO_AVAILABLE_SLOTS;
    const disableCTAButton =
      disableCTAWhenNoAvailabilityOnSelectedDate ||
      calendarErrors.some(
        (error) =>
          error === CalendarErrors.NO_NEXT_AVAILABLE_DATE_WARNING ||
          (isShowPricingPlanEndDateIndicationEnabled &&
            error ===
              CalendarErrors.NO_VALID_PRICING_PLAN_IN_RESCHEDULE_FLOW_ERROR),
      );

    const ctaText = getCtaText({
      state,
      context,
      bookingPreferences,
    });

    const ctaFullWidth = settings.get(settingsParams.buttonsFullWidth);

    ctaViewModel = {
      disabled: disableCTAButton,
      label: ctaText,
      fullWidth: ctaFullWidth,
    };
  }

  return {
    serviceName,
    paymentDescription,
    dateAndTime,
    videoConferenceBadgeText,
    preferences: {
      bookingPreferences: getBookingPreferencesOptionsWithEnrichedValue({
        bookingPreferences,
        waitlistIndicationText: getContent({
          settingsParam: settingsParams.waitlistIndication,
          translationKey: 'app.settings.defaults.waitlist',
        }),
        t,
      }),
      clearText: settings.get(settingsParams.bookingDetailsClearText),
      titleText: getContent({
        settingsParam: settingsParams.preferencesTitle,
        translationKey:
          'app.settings.defaults.booking-details.preferences.title',
      }),
    },
    ctaViewModel,
    ...(isShowPricingPlanEndDateIndicationEnabled ? { alert } : {}),
  };
}

export function createDummyBookingDetailsViewModel({
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): BookingDetailsViewModel {
  const { t, settings, getContent, settingsParams } = context;

  const ctaViewModel: CtaViewModel = {
    label: getContent({
      settingsParam: settingsParams.nextButton,
      translationKey: 'app.settings.defaults.booking-details.book-now.text',
    }),
    disabled: false,
    fullWidth: settings.get(settingsParams.buttonsFullWidth),
  };

  return {
    videoConferenceBadgeText: settings.get(
      settingsParams.videoConferenceBadgeVisibility,
    )
      ? getVideoConferencingBadge(context)
      : '',
    serviceName: t('dummy-content.service.name'),
    paymentDescription: t('dummy-content.service.price'),
    ctaViewModel,
    preferences: {
      bookingPreferences: [
        {
          error: {
            key: CalendarErrors.NO_SELECTED_LOCATION_ERROR,
            message: '',
          },
          key: Preference.LOCATION,
          placeholder: '',
          options: [
            {
              value: getContent({
                settingsParam: settingsParams.locationLabel,
                translationKey: 'dummy-content.service.location',
              }),
            },
          ],
        },
        {
          error: {
            key: CalendarErrors.NO_SELECTED_STAFF_MEMBER_ERROR,
            message: '',
          },
          key: Preference.STAFF_MEMBER,
          placeholder: '',
          options: [
            {
              value: getContent({
                settingsParam: settingsParams.staffMemberLabel,
                translationKey: 'dummy-content.service.staff',
              }),
            },
          ],
        },
        {
          error: {
            key: CalendarErrors.NO_SELECTED_DURATION_ERROR,
            message: '',
          },
          key: Preference.DURATION,
          placeholder: '',
          options: [
            {
              value: getContent({
                settingsParam: settingsParams.durationLabel,
                translationKey: 'dummy-content.service.duration',
              }),
            },
          ],
        },
      ],
      clearText: settings.get(settingsParams.bookingDetailsClearText),
      titleText: getContent({
        settingsParam: settingsParams.preferencesTitle,
        translationKey:
          'app.settings.defaults.booking-details.preferences.title',
      }),
    },
  };
}

const getCtaText = ({
  state,
  bookingPreferences,
  context,
}: {
  state: CalendarState;
  context: CalendarContext;
  bookingPreferences: BookingPreference[];
}): string => {
  const { selectableSlotsAtSelectedTime, selectedBookingPreferences } = state;
  const { getContent, settingsParams } = context;
  const isRescheduling = isReschedulingFlow(state);
  const isPendingApproval = isPendingApprovalFlow(state);
  const isWaitingList = isWaitingListFlow({
    selectableSlots: selectableSlotsAtSelectedTime,
    selectedBookingPreferences,
    bookingPreferences,
  });
  if (isRescheduling) {
    return getContent({
      settingsParam: settingsParams.rescheduleButton,
      translationKey: 'app.rescheduled-booking.booking-details.cta',
    });
  }
  if (isPendingApproval) {
    return getContent({
      settingsParam: settingsParams.pendingApprovalButton,
      translationKey:
        'app.settings.defaults.booking-details.pending-approval.text',
    });
  }
  if (isWaitingList) {
    return getContent({
      settingsParam: settingsParams.joinWaitlistButton,
      translationKey:
        'app.settings.defaults.booking-details.join-waitlist.text',
    });
  }
  return getContent({
    settingsParam: settingsParams.nextButton,
    translationKey: 'app.settings.defaults.booking-details.book-now.text',
  });
};

const isReschedulingFlow = (state: CalendarState) =>
  !!state.rescheduleBookingDetails;

const isPendingApprovalFlow = (state: CalendarState) =>
  !!state.availableServices[0].policy?.isPendingApprovalFlow;

const getBookingPreferencesOptionsWithEnrichedValue = ({
  bookingPreferences,
  waitlistIndicationText,
  t,
}: {
  bookingPreferences: BookingPreference[];
  waitlistIndicationText: string;
  t: TFunction;
}): BookingPreference[] => {
  return bookingPreferences.map((bookingPreference: BookingPreference) => {
    let options: BookingPreferenceOption[];
    if (bookingPreference.options.length > 1) {
      options = bookingPreference.options.map(
        (bookingPreferenceOption: BookingPreferenceOption) => {
          const valueWithWaitlistOnlyIndication = t(
            'app.booking-details.dropdowns.option-with-waitlist-only',
            {
              option: bookingPreferenceOption.value,
              waitlistOnly: waitlistIndicationText,
            },
          );
          return {
            ...bookingPreferenceOption,
            value: bookingPreferenceOption.isWithWaitingList
              ? valueWithWaitlistOnlyIndication
              : bookingPreferenceOption.value,
          };
        },
      );
    } else {
      options = bookingPreference.options;
    }

    return {
      ...bookingPreference,
      options,
    };
  });
};
