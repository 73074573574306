import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import {
  convertRfcTimeToLocalDateTimeStartOfDay,
  getDateTimeFromLocalDateTime,
} from '../dateAndTime/dateAndTime';

export const isAtLeastOneSlotIsWithOpenWaitingList = (
  slots: SlotAvailability[],
): boolean => {
  return slots.some((slotAvailability: SlotAvailability) =>
    isSlotWithOpenWaitingList(slotAvailability),
  );
};

export const isAllSlotsAreFull = (slots: SlotAvailability[]): boolean => {
  return slots.every((slotAvailability: SlotAvailability) =>
    isFullSlot(slotAvailability),
  );
};

export const isTooLateToBookAllSlots = (slots: SlotAvailability[]): boolean => {
  return slots.every((slotAvailability: SlotAvailability) =>
    isTooLateToBookSlot(slotAvailability),
  );
};

export const isTooEarlyToBookAllSlots = (
  slots: SlotAvailability[],
): boolean => {
  return slots.every((slotAvailability: SlotAvailability) =>
    isTooEarlyToBookSlot(slotAvailability),
  );
};

export const isSlotWithOpenWaitingList = (slot: SlotAvailability): boolean => {
  return isFullSlot(slot) && Number(slot.waitingList?.openSpots) > 0;
};

export const isFullSlot = (slot: SlotAvailability): boolean => {
  return Number(slot.openSpots) === 0;
};

export const isTooEarlyToBookSlot = (slot: SlotAvailability): boolean => {
  return !!slot.bookingPolicyViolations?.tooEarlyToBook;
};

export const isTooLateToBookSlot = (slot: SlotAvailability): boolean => {
  return !!slot.bookingPolicyViolations?.tooLateToBook;
};

export const isSlotNoBookFlow = (slot: SlotAvailability): boolean => {
  return !!slot.bookingPolicyViolations?.bookOnlineDisabled;
};

export const isSlotStartsOnDate = ({
  rfcStartTime,
  dateAsLocalDate,
}: {
  rfcStartTime: string;
  dateAsLocalDate: string;
}) => {
  const startOfDay = convertRfcTimeToLocalDateTimeStartOfDay(rfcStartTime);
  return (
    getDateTimeFromLocalDateTime(startOfDay).getTime() ===
    getDateTimeFromLocalDateTime(dateAsLocalDate).getTime()
  );
};
