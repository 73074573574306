import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { SetSelectedMonth } from '../setSelectedMonth/setSelectedMonth';
import { SetSelectedDate } from '../setSelectedDate/setSelectedDate';
import { SetSelectedRange } from '../setSelectedRange/setSelectedRange';
import {
  LocalDateTimeRange,
  TriggeredByOptions,
} from '../../../../types/types';
import {
  isDailyAgendaWeeklyPickerLayout,
  isDailyTimeSlotsWeeklyPickerLayout,
  isWeeklyTimeSlotsLayout,
  isWeeklyTimetableLayout,
} from '../../../../utils/layouts';

export type RefetchPlatformData = (
  triggeredBy: TriggeredByOptions,
) => Promise<void>;

export function createRefetchPlatformDataAction(
  {
    getControllerState,
    context,
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  setSelectedDate: SetSelectedDate,
  setSelectedMonth: SetSelectedMonth,
  setSelectedRange: SetSelectedRange,
): RefetchPlatformData {
  return async (triggeredBy) => {
    const [state] = getControllerState();
    const { selectedRange, selectedDate } = state;
    const { settings, settingsParams, experiments } = context;
    const isWeeklyLayoutMobileEnabled = experiments.enabled(
      'specs.bookings.weeklyLayoutMobile',
    );

    if (
      isWeeklyTimeSlotsLayout(settings, settingsParams) ||
      isWeeklyTimetableLayout(settings, settingsParams)
    ) {
      const range: LocalDateTimeRange = {
        fromAsLocalDateTime: selectedRange!.from,
        toAsLocalDateTime: selectedRange!.to,
      };
      await setSelectedRange(range, triggeredBy);
    } else if (
      (isWeeklyLayoutMobileEnabled &&
        isDailyTimeSlotsWeeklyPickerLayout(settings, settingsParams)) ||
      isDailyAgendaWeeklyPickerLayout(settings, settingsParams)
    ) {
      const range: LocalDateTimeRange = {
        fromAsLocalDateTime: selectedRange!.from,
        toAsLocalDateTime: selectedRange!.to,
      };
      await Promise.all([
        setSelectedRange(range, triggeredBy),
        setSelectedDate(selectedDate!, triggeredBy),
      ]);
    } else {
      await Promise.all([
        setSelectedDate(selectedDate!, triggeredBy),
        setSelectedMonth(selectedRange!.from, triggeredBy),
      ]);
    }
  };
}
