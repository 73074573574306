import { TimezoneType } from '@wix/bookings-uou-types';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import {
  getEndOfMonthAsLocalDateTime,
  getLocalTimezone,
  getStartOfMonthAsLocalDateTime,
  getTodayLocalDateTimeStartOfDay,
} from '../../../../utils/dateAndTime/dateAndTime';
import { BottomSectionStatus } from '../../ViewModel/widgetViewModel/widgetViewModel';
import { SetSelectedMonth } from '../setSelectedMonth/setSelectedMonth';
import { CalendarState } from '../../controller';
import { SetSelectedDate } from '../setSelectedDate/setSelectedDate';
import { TriggeredByOptions } from '../../../../types/types';
import { SetSelectedRange } from '../setSelectedRange/setSelectedRange';
import { getLocalDateTimeRangeForDay } from '../../../../utils/getLocalDateTimeRangeForDay/getLocalDateTimeRangeForDay';
import { BookingsQueryParams } from '@wix/bookings-adapter-ooi-wix-sdk';
import { AddError } from '../addError/addError';
import { InitializeCalendarDateOptions } from '../../../../utils/bi/consts';
import {
  bookingsCalendarPageLoaded,
  bookingsCalendarFiltersLoad,
  bookingsCalendarBookingDetailsLoad,
} from '@wix/bi-logger-wixboost-ugc/v2';
import {
  isDailyAgendaWeeklyPickerLayout,
  isDailyTimeSlotsWeeklyPickerLayout,
  isWeeklyTimeSlotsLayout,
  isWeeklyTimetableLayout,
} from '../../../../utils/layouts';

export type InitializeWidget = () => Promise<void>;

export function createInitializeWidgetAction(
  {
    getControllerState,
    context,
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  setSelectedDate: SetSelectedDate,
  setSelectedMonth: SetSelectedMonth,
  setSelectedRange: SetSelectedRange,
  addError: AddError,
): InitializeWidget {
  return async () => {
    const {
      wixSdkAdapter,
      biLogger,
      settings,
      settingsParams,
      businessInfo,
      experiments,
    } = context;

    if (wixSdkAdapter.isSSR()) {
      return;
    }

    const [, setState] = getControllerState();

    const selectedTimezone = getSelectedTimezone(context);
    setState({ selectedTimezone });

    const [state] = getControllerState();
    const initialLocalDate = await getInitializeCalendarDate({
      state,
      context,
      addError,
    });

    const isDailyTimeSlotWeeklyPickerLayoutEnable = experiments.enabled(
      'specs.bookings.weeklyLayoutMobile',
    );
    if (
      isWeeklyTimeSlotsLayout(settings, settingsParams) ||
      isWeeklyTimetableLayout(settings, settingsParams)
    ) {
      const range = getLocalDateTimeRangeForDay(
        businessInfo!.dateRegionalSettingsLocale!,
        initialLocalDate,
      );
      await setSelectedRange(range, TriggeredByOptions.INITIALIZE_WIDGET);
    } else if (
      (isDailyTimeSlotWeeklyPickerLayoutEnable &&
        isDailyTimeSlotsWeeklyPickerLayout(settings, settingsParams)) ||
      isDailyAgendaWeeklyPickerLayout(settings, settingsParams)
    ) {
      const range = getLocalDateTimeRangeForDay(
        businessInfo!.dateRegionalSettingsLocale!,
        initialLocalDate,
      );
      await Promise.all([
        setSelectedRange(range, TriggeredByOptions.INITIALIZE_WIDGET),
        setSelectedDate(initialLocalDate, TriggeredByOptions.INITIALIZE_WIDGET),
      ]);
    } else {
      const startOfMonthAsLocalDateTime =
        getStartOfMonthAsLocalDateTime(initialLocalDate);
      await Promise.all([
        setSelectedDate(initialLocalDate, TriggeredByOptions.INITIALIZE_WIDGET),
        setSelectedMonth(
          startOfMonthAsLocalDateTime,
          TriggeredByOptions.INITIALIZE_WIDGET,
        ),
      ]);
    }

    setState({ bottomSectionStatus: BottomSectionStatus.LOADED });

    biLogger.report(bookingsCalendarPageLoaded({}));
    biLogger.report(
      bookingsCalendarBookingDetailsLoad({
        triggeredBy: TriggeredByOptions.INITIALIZE_WIDGET,
      }),
    );
    biLogger.report(
      bookingsCalendarFiltersLoad({
        triggeredBy: TriggeredByOptions.INITIALIZE_WIDGET,
        selectedFilters: JSON.stringify(state.filterOptions),
      }),
    );
  };
}

function getSelectedTimezone({ businessInfo, wixSdkAdapter }: CalendarContext) {
  const localTimezone = getLocalTimezone();
  const preSelectedTimezone = wixSdkAdapter.getUrlQueryParamValue(
    BookingsQueryParams.TIMEZONE,
  );

  const isPreselectedTimezoneValid = [
    businessInfo!.timeZone,
    localTimezone,
  ].includes(preSelectedTimezone);

  const defaultTimezone =
    businessInfo!.timezoneProperties?.defaultTimezone === TimezoneType.CLIENT
      ? localTimezone
      : businessInfo!.timeZone!;

  return isPreselectedTimezoneValid ? preSelectedTimezone : defaultTimezone;
}

const getInitializeCalendarDate = async ({
  state,
  context,
  addError,
}: {
  state: CalendarState;
  context: CalendarContext;
  addError: AddError;
}): Promise<string> => {
  const { selectedDate, selectedTimezone } = state;
  const { wixSdkAdapter, experiments, settings, settingsParams, calendarApi } =
    context;
  const isAnonymousCancellationFlow =
    wixSdkAdapter.getUrlQueryParamValue(BookingsQueryParams.REFERRAL) ===
    'batel';

  const todayLocalDateTime = getTodayLocalDateTimeStartOfDay(selectedTimezone!);

  if (isAnonymousCancellationFlow) {
    return selectedDate || todayLocalDateTime;
  }
  const shouldInitializeCalendarOnToday =
    settings.get(settingsParams.initializeCalendarDate) ===
    InitializeCalendarDateOptions.TODAY;
  if (shouldInitializeCalendarOnToday) {
    return todayLocalDateTime;
  } else {
    const sixMonthsFromNow = getEndOfMonthAsLocalDateTime(
      todayLocalDateTime,
      7,
    );
    const nextAvailableDate = await calendarApi.getNextAvailableDate(
      {
        fromAsLocalDateTime: todayLocalDateTime,
        toAsLocalDateTime: sixMonthsFromNow,
      },
      { state, onError: addError },
    );
    return nextAvailableDate || todayLocalDateTime;
  }
};
